import React from 'react';
import Layout from 'layout';
import Button from 'components/ui/button';
import Link from 'components/ui/link';

const ContentSpecialist = () => (
  <Layout
    metaTitle="Job - Product Marketing Manager"
    canonical="/jobs/product-marketing-manager/"
    metaDescription="We are looking for Product Marketing Manager - join us if you are looking for new challenges!"
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 text-left p-4 m-auto">
            <h1 className="mb-5 text-center">Product Marketing Manager</h1>
            <p className="mb-3">
              We’re looking for a talented <strong>Marketing Manager</strong> with a passion for
              digital marketing, product growth, and technology.
            </p>
            <p>
              LiveSession is a growing B2B SaaS company that helps companies understand how
              customers use their websites and apps. Join us and help us create the best qualitative
              analytics software on the market!
            </p>
            <p>
              This is a permanent, <strong>full-time</strong> remote position.
            </p>
            <p className="mt-4">Responsibilities: </p>
            <ul className="">
              <li>
                managing digital marketing campaigns, including web, SEO/SEM, email, social media,
                PPC, and email automation flows
              </li>
              <li>
                supporting, coordinating content team and cooperating with content agencies and
                other writers (topic & keyword research, distribution, link building){' '}
              </li>
              <li>cooperation with Web Developer & UX Designer</li>
              <li>
                managing LiveSession{' '}
                <Link href="/early-stage-program/" target="_blank" rel="noopener noreferrer">
                  Early Stage
                </Link>{' '}
                and{' '}
                <Link href="/partners/" target="_blank" rel="noopener noreferrer">
                  Affiliate Program
                </Link>
              </li>
            </ul>
            <p className="mt-4">Requirements: </p>
            <ul>
              <li>
                fluency in English along with excellent, written and oral communication skills
                (minimum C1)
              </li>
              <li>strong planning, organizational, and management skills</li>
              <li>self-starter, dynamic, proactive approach</li>
              <li>experience in working on a similar position (SaaS experience is a big plus!)</li>
              <li>
                knowledge of tools such as Google Analytics, Google Tag Manager, Google Search
                Console, Google Ads, Facebook Ads, Ahrefs, Sendinblue
              </li>
              <li>the ability and desire to work in a fast-growing startup environment</li>
            </ul>
            <p className="mt-4">Benefits: </p>
            <ul>
              <li>salary tailored to your experience, skills, and performance</li>
              <li>work autonomy with decision-making position</li>
              <li>fully remote with flexible working hours</li>
              <li>startup atmosphere</li>
            </ul>
            <p className="mt-4">
              Please send us your CV and a few words about yourself to:{' '}
              <Link href="mailto:jobs@livesession.io">jobs@livesession.io</Link>
            </p>
            <p
              style={{
                margin: '3rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button href="mailto:jobs@livesession.io">Apply</Button>
            </p>
            <p className="mt-5" style={{ fontSize: '0.8rem', color: 'grey' }}>
              By sending a recruitment application to LiveSession Sp. z o.o. with headquarters in
              Wroclaw, at gen. Wladyslawa Sikorskiego 3/2, 56-659, Wroclaw, entered in the register
              of entrepreneurs of the National Court Register under number 0000753134, Tax ID:
              PL8971860301, REGON: 381580380, you agree to the processing of personal data contained
              in the recruitment application by the Company in order to recruit for the position
              indicated in the announcement.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContentSpecialist;
